.scrollbarHidden {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbarHidden::-webkit-scrollbar {
  display: none;
}

.scrollbarHidden::-webkit-scrollbar-track {
  display: none;
}

.scrollbarHidden::-webkit-scrollbar-thumb {
  display: none;
}
